<template>
  <div class="apply__container mobileForm">
    <el-form
      ref="signUpForm"
      class="custom-form"
      :model="signUpForm"
      inline
    >
      <div>
        <h3 class="font-bold text-base mt-2 mb-5">
          <span>
            <span>지원정보</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item>
            <el-select
              v-model="applicantForm.jobId"
              clearable
              :placeholder="$t('recruit.form.job')"
              filterable
              value=""
            >
              <el-option
                v-for="item in appliableList"
                :key="item.id"
                :label="item.alias"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
        </div>
      </div>

      <div>
        <h3 class="font-bold text-base mt-1 mb-5">
          <span>
            <span>이름</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="name">
            <el-input
              v-model.trim="signUpForm.name"
              placeholder="홍길동"
              name="namefield"
              class="filter-item"
            />
          </el-form-item>
        </div>
      </div>

      <div>
        <h3 class="font-bold text-base mt-1 mb-5">
          <span>
            <span>생년월일</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="birthday">
            <el-input
              v-model.trim="signUpForm.birthday"
              :maxlength="10"
              placeholder="1990.01.01"
              class="filter-item"
              @keydown.native="autoCompleteBirthday"
            />
          </el-form-item>
        </div>
      </div>

      <div>
        <h3 class="font-bold text-base mt-1 mb-5">
          <span>
            <span>연락처</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="phone">
            <el-input
              v-model.trim="signUpForm.phone"
              :maxlength="11"
              placeholder="-없이 입력해주세요."
              type="tel"
              class="filter-item"
            />
          </el-form-item>
        </div>
      </div>

      <div>
        <h3 class="font-bold text-base mt-1 mb-5">
          <span>
            <span>이메일</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item
            class="email__ipt"
            prop="email"
          >
            <el-input
              v-model.trim="signUpForm.email"
              :minlength="10"
              :placeholder="$t('recruit.form.email')"
              type="email"
              class="filter-item"
            />
            <p
              class="ml-1 text-xs mt-2"
              style="color: #777;"
            >
              <i
                class="relative"
                style="font-size: 13px; top: 1px;"
              >*</i>
              비밀번호 분실 시, 비밀번호 변경을 위한 사용자 인증 이메일 주소를 입력해주세요.
            </p>
          </el-form-item>
        </div>
      </div>

      <div>
        <h3 class="font-bold text-base mt-1 mb-5">
          <span>
            <span>비밀번호</span>
          </span>
          <i class="highlight__star required">*</i>
        </h3>
        <div>
          <el-form-item prop="password">
            <el-input
              v-model.trim="signUpForm.password"
              show-password
              :minlength="8"
              :maxlength="20"
              :placeholder="$t('recruit.form.password')"
              type="password"
              class="filter-item"
            />
          </el-form-item>
          <el-form-item prop="passwordConfirm">
            <el-input
              v-model.trim="signUpForm.passwordConfirm"
              show-password
              :minlength="8"
              :maxlength="20"
              :placeholder="$t('recruit.form.passwordConfirm')"
              type="password"
              class="filter-item"
            />
          </el-form-item>
          <p
            class="ml-1 text-xs"
            style="color: #777;"
          >
            <i
              class="relative"
              style="font-size: 13px; top: 1px;"
            >*</i>
            비밀번호는 영문, 숫자를 포함하여 공백없이 8 ~ 20자로 설정해주세요.
          </p>
        </div>
      </div>

      <div class="mt-2">
        <el-checkbox
          v-model="privacyCheck"
          class="mt-4 mb-4"
        >
          개인정보의 수집 및 이용에 동의
          <i class="highlight__star required">*</i>
        </el-checkbox>
        <div
          class="overflow-y-auto border-gray-300 box-border"
          style="height: 130px; border: 1px solid rgb(233, 232, 232);"
        >
          <div class="text-sm leading-6 text-gray-500 pr-2 pl-2">
            <p
              class="mt-3"
              style="text-indent: 4px;"
            >
              (주)쿡앱스는 지원자의 입사전형 진행 및 인재풀 구성을 위해 아래와 같은 목적으로 개인정보를 수집 및 이용합니다.
            </p>
            <ul>
              <li>이력서 및 경력기술서 제출 시 전형결과 안내 및 자격요건 확인에 필요한 최소한의 개인 정보만 기입해주시길 바랍니다. </li>
              <li>주민번호, 가족관계, 종교, 신체정보 등의 불필요한 개인 정보는 삭제해주시길 바랍니다.</li>
            </ul>
            <div class="mb-3">
              <br>
              <h4 class="mt-2 mb-2 font-bold">
                1.개인정보의 수집 및 이용 목적
              </h4><br>
              <p class="ml-3">
                입사전형 진행,자격요건 확인, 전형별 합격여부 확인,전형결과 안내
              </p><br>
              <h4 class="mt-2 mb-2 font-bold">
                2. 수집하는 개인정보의 항목
              </h4><br>
              <p class="ml-3">
                필수정보 : 성명, 생년월일,이메일주소, 휴대전화번호,국적,경력사항(입사일,퇴사일,회사명,직급,담당업무,프로젝트 수행이력)
                학력,전공,병역사항,생성정보(접속정보,로그기록 등)
              </p>
              <p class="ml-3">
                선택정보 : 자격사항, 수상내역, 어학사항, 봉사활동, 보훈사항, 논문사항 등
              </p><br>
              <h4 class="mt-2 mb-2 font-bold">
                3. 개인정보의 보유 및 이용기간
              </h4><br>
              <p class="ml-3">
                1) 채용여부 확정 이후 최대 3년까지 보관되며 이후 지체없이 파기합니다.
              </p>
              <p class="ml-3">
                2) 보관기간 이내라도 입사지원자가 개인정보의 삭제를 원하는 경우 지체없이 해당 정보를 파기합니다.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4 pb-5">
        <p>
          <el-checkbox
            v-model="signUpForm.recruitPoolCheck"
            class="mt-4 mb-4"
          >
            인재풀 등록 및 채용정보 수신 동의
          </el-checkbox>
        </p>
        <div
          class="overflow-y-auto border-gray-300 box-border"
          style="height: 90px; border: 1px solid rgb(233, 232, 232);"
        >
          <div class="text-sm leading-6 text-gray-500 pr-2 pl-2">
            <p
              class="mt-3"
              style="text-indent: 4px;"
            >
              입사지원자는 상기 개인정보를 상시채용을 위해 인력풀에 등록하여
              <span
                class="font-primary-color"
                style="font-weight: bold;"
              >5년간 보유하는 것에</span>
              동의하며, 이메일로 쿡앱스 채용정보를 받아보겠습니다.
            </p>
            <p
              class="mt-3"
              style="text-indent: 4px;"
            >
              인재풀에 등록될 경우 관련 직무 채용 관련 이메일 수신에 동의하며 수신을 원하지 않을 경우 언제든지 거부 요청할 수 있습니다.
            </p>
          </div>
        </div>
      </div>

      <div class="mobileForm__btnWrap">
        <button
          class="customBtn mdlg block"
          style="width: 100%; max-width: 414px; margin: 32px auto;"
          @click.prevent="applySimpleMobile"
        >
          <span>1차 작성 완료</span>
        </button>
      </div>
    </el-form>
  </div>
</template>
<script>
import { getJobs } from '@/api/job';
import { signup } from '@/api/applicant';
import { mobileSimpleApply } from '@/api/applicant';
import '@/plugins/element.js';

export default {
  name: 'MobileForm',
  data() {
    return {
      privacyCheck: false,
      signUpForm: {
        name: '',
        birthday: '',
        email: '',
        phone: '',
        password: '',
        passwordConfirm: '',
        recruitPoolCheck: false,
      },
      basicRules: {
        name: [{required: true, trigger: 'blur'}],
        birthday: [{required: true, trigger: 'blur'}],
        email: [{required: true, trigger: 'blur'}],
        phone: [{required: true, trigger: 'blur'}],
        password: [{required: true, trigger: 'blur'}],
        passwordConfirm: [{required: true, trigger: 'blur'}],
      },
      applicationId: 0,
      applicantForm: {
        name: '',
        phone: '',
        email: '',
        jobId: '',
        country: '',
        visaCheck: '',
        visaType: '',
        visaExpiryDate: '',
      },
      selectedValue: [],
      appliableList: [],
      cascaderList: []
    };
  },
  watch:{
    selectedValue() {
      if(typeof this.selectedValue[0] === 'number'){
        this.$store.dispatch('app/setRecruitType',this.selectedValue[0]);
        this.$store.dispatch('user/setJobId',this.selectedValue[1]);
      }
    },
  },
  mounted() {
    if(JSON.parse(localStorage.getItem('applicantForm'))!==null) {
      this.setLocalStorage();
    }
  },
  async created() {
    await this.getJobList();
    if (this.$store.getters.jobId) {
      this.applicantForm.jobId = parseInt(this.$store.getters.jobId, 10);
      const job = this.appliableList.find(j => j.id === this.applicantForm.jobId);
      this.selectedValue = [job.jobTypeId, job.id];
    }
  },
  methods: {
    async getJobList() {
      const query = {};
      const result = await getJobs(query);
      const list = [];
      const cascaderList = [{
        value: 1,
        label: '게임기획/시나리오',
        children: []
      }, {
        value: 2,
        label: '데이터 분석',
        children: []
      }, {
        value: 3,
        label: '마케팅',
        children: []
      }, {
        value: 4,
        label: '아트',
        children: []
      }, {
        value: 6,
        label: '프로그래밍',
        children: []
      }, {
        value: 7,
        label: '전문연구요원',
        children: []
      },{
        value: 12,
        label: '인사/조직문화',
        children: []
      }, {
        value: 13,
        label: '영상/미디어',
        children: []
      }, {
        value: 14,
        label: '프리랜서',
        children: []
      }, {
        value: 15,
        label: '관계사',
        children: []
      }, {
        value: 16,
        label: '인턴',
        children: []
      }];

      result.data.items.forEach(type => {
        type.job.forEach(j => {
          const jobObj = {
            type: type.name,
            type_en: type.name_en,
            ...j
          };

          list.push(jobObj);

          const parent = cascaderList.find(c => c.value === j.jobTypeId);
          if (parent) {
            parent.children.push({
              value: j.id,
              label: j.alias || j.name
            });
          }
        });
      });
      this.appliableList = list;
      this.cascaderList = cascaderList;
    },
    autoCompleteBirthday: function (key) {
      const birthday = this.signUpForm.birthday;
      const year = birthday.substr(0,4);
      const firstDot = birthday.substr(4,1);
      const month = birthday.substr(5,2);
      const secondDot = birthday.substr(7,1);
      if( key.keyCode == 8 || key.keyCode == 46 || key.key === '.' ) return false;
      if(birthday.length > 3 && firstDot !== '.'){
        this.signUpForm.birthday = year + '.';
      }else if(birthday.length > 6 && secondDot !== '.'){
        this.signUpForm.birthday = year + '.' + month + '.';
      }
    },
    validateBirthday() {
      let isBirthdayValid = false;
      const birthday = this.signUpForm.birthday;
      var regex = /^(19[0-9][0-9]|20\d{2}).(0[0-9]|1[0-2]).(0[1-9]|[1-2][0-9]|3[0-1])$/;
      if(!regex.test(birthday)){
        this.$message({
          message: '생년월일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isBirthdayValid = true;
      }
      return isBirthdayValid;
    },
    validateEmail() {
      let isEmailValid = false;
      const email = this.signUpForm.email;
      const regex = /\S+@\S+\.\S+/;
      if(!regex.test(email)){
        this.$message({
          message: '이메일을 다시 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isEmailValid = true;
      }
      return isEmailValid;
    },
    validatePhone() {
      let isPhoneVaild = false;
      const phone = this.signUpForm.phone;
      const regex = /^\d{3}\d{4}\d{4}$/;
      if(!regex.test(phone)){
        this.$message({
          message: '연락처는 숫자로만 11자리를 입력해주세요.',
          type: 'error'
        });
        return false;
      }else{
        isPhoneVaild = true;
      }
      return isPhoneVaild;
    },
    validatePassword() {
      let isPwVaild = false;
      const pw = this.signUpForm.password;
      const pwConfirm = this.signUpForm.passwordConfirm;
      const num = pw.search(/[0-9]/g);
      const eng = pw.search(/[a-z]/ig);

      if(pw.length < 8 || pw.length > 20){
        this.$message({
          message: '비밀번호는 8자리 ~ 20자리 이내로 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw.search(/\s/) != -1){
        this.$message({
          message: '비밀번호는 공백 없이 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(num < 0 || eng < 0 ){
        this.$message({
          message: '비밀번호를 영문,숫자를 혼합하여 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(pw !== pwConfirm){
        this.$message({
          message: '비밀번호가 다릅니다.',
          type: 'error'
        });
      }else {
        isPwVaild = true;
      }
      return isPwVaild;
    },
    // validateSignUpForm() {
    //   let isValid = false;
    //   this.$refs['signUpForm'].validate((valid) => {
    //     isValid = valid;
    //   });
    //   return isValid;
    // },
    validateSignUpForm() {
      if(!this.applicantForm.jobId){
        this.$message({
          message: '직무를 선택해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.signUpForm.name){
        this.$message({
          message: '이름을 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.signUpForm.birthday){
        this.$message({
          message: '생년월일을 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.validateBirthday()) {
        return false;
      }else if(!this.signUpForm.phone) {
        this.$message({
          message: '연락처를 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.validatePhone()) {
        return false;
      }else if(!this.signUpForm.email) {
        this.$message({
          message: '이메일을 입력해주세요.',
          type: 'error'
        });
        return false;
      }else if(!this.validateEmail()) {
        return false;
      }else if(!this.validatePassword()){
        return false;
      }else if (!this.privacyCheck) {
        this.$message({
          message: '개인정보 수집 및 이용에 동의해주세요.',
          type: 'error'
        });
        return false;
      }
      return true;
    },
    async applySimpleMobile() {

      if(!this.validateSignUpForm()){
        return false;
      }

      const signUpResult = await signup(this.signUpForm);

      if(!signUpResult.data.success){
        this.$message({
          message: signUpResult.data.message,
          type: 'error'
        });
      }else{

        this.applicantForm.name = this.signUpForm.name;
        this.applicantForm.birthday = this.signUpForm.birthday;
        this.applicantForm.phone = this.signUpForm.phone;
        this.applicantForm.email = this.signUpForm.email;
        this.applicantForm.recruitPoolCheck = this.signUpForm.recruitPoolCheck;

        const applyResult = await mobileSimpleApply({
          applicantForm: this.applicantForm,
          isTemp: true,
          isMobileSimpleApply: true,
          accountId: signUpResult.data.accountId
        });

        if(!applyResult.data.success){
          this.$message({
            message: applyResult.data.message,
            type: 'error'
          });
        }else{
          alert('작성이 완료되었습니다! 작성해주신 이메일로 자세한 지원 방법을 보내 드릴 예정입니다.');
          this.$router.push('/index?status=mobileSignUpComplete');
        }
      }

    },
  }
};
</script>
<style lang="scss">
.apply__container.mobileForm{
  .el-form-item__content{
    width: 100%;
    max-width: 414px;
  }
  .el-form-item{
    width: 100%;
    max-width: 414px;
  }
  .el-select{
    width: 100%;
    max-width: 414px;
  }
  .el-select-dropdown.el-popper{
    z-index: 9 !important;
  }
}
</style>
<style lang="scss" scoped>
@import "@/styles/apply.scss";
</style>
