<template>
  <div
    class="app-container apply__new"
    style="padding-bottom: 90px !important"
  >
    <h1 class="apply__tit font-bold text-3xl mt-5 mb-5">
      <span class="highlight">
        <span>📝 지원서 작성하기</span>
      </span>
    </h1>
    <MobileForm />
  </div>
</template>

<script>
import MobileForm from './components/mobileForm.vue';

export default {
  name: 'JobInform',
  components: {
    MobileForm,
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/apply.scss";
</style>